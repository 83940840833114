import styled from "styled-components";
import { Settings } from "../../profile/ProfileSettings";
import { screenSize } from "../../../common/styles/ScreenSizes";
import {
  BodyTextRocket,
  Link,
  LinkRocket,
  ThemedButtonRocket,
} from "ccp-common-ui-components";
import { rdsBorderRadius, rdsMargin, rdsPadding, theme } from "@coles/rocket";

export const ReceiptPreferencesContainer = styled(Settings)`
  padding: 18px 25px;
  position: relative;
`;

export const ReceiptPreferencesDescriptionBold = styled.p`
  font-size: 18px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 8px;
  font-weight: bold;

  @media ${screenSize.mobile} {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const ReceiptPreferencesDescriptionBoldLvl2 = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 0px;
  font-weight: bold;

  @media ${screenSize.mobile} {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const ReceiptPreferencesDescription = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 18px;
  margin-top: 0px;

  @media ${screenSize.mobile} {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const ReceiptPreferencesButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-top: 12px;
  align-items: center;
`;

export const CancelLink = styled(Link)`
  margin-top: 12px;
  margin-right: auto;
  margin-left: auto;
`;

export const Divider = styled.div`
  border-top: 1px solid black;
`;

export const ContainerRocket = styled.div`
  ${rdsMargin.s8.bottom};
  ${rdsMargin.s6.left};
  ${rdsMargin.s6.right};
  ${rdsPadding.s6.horizontal};
  width: 100%;
  position: relative;
  @media ${screenSize.mobile} {
    margin: 0px;
  }
`;

export const HeaderSectionPreferencesRocket = styled.div`
  ${rdsMargin.s4.bottom};
`;

export const HeadingContainerPreferencesRocket = styled.div`
  ${rdsPadding.s2.left};
  ${rdsPadding.s2.bottom};
`;

export const ReceiptPreferencesContainerRocket = styled.div`
  align-self: center;
  border-top: 2px #e0e0e0 solid;

  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

export const StyledOL = styled.ol`
  margin-block-start: 0.5em;
  padding-inline-start: 1.25em;
  ${rdsPadding.s5.bottom};
`;

export const RowAlignedDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: centre;
`;

export const LinkRocketStyled = styled(LinkRocket)`
  padding: 0;
`;

export const ReceiptPreferencesCardContainerRocket = styled.div`
  ${rdsMargin.s2.top};
  align-self: center;
  border: ${theme.rdsBorderWidth.default} solid ${theme.colorBorder.tertiary};
  border-radius: ${rdsBorderRadius.md};

  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

export const JustifyCenterCardDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: centre !important;
  ${rdsPadding.s2.vertical};
  ${rdsMargin.s2.horizontal};
`;

export const BodyTextCardDiv = styled.div`
  ${rdsPadding.s4.horizontal};
  ${rdsPadding.s1.top};
`;

export const IconCardDiv = styled.div`
  align-self: center;
  ${rdsPadding.s2.left};
`;

export const ButtonCardDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  ${rdsPadding.s2.right};
  ${rdsPadding.s2.left};
`;

export const LineLabelRocketCardStyled = styled(BodyTextRocket).attrs({
  variant: "body_300_bold",
})``;

export const ThemedButtonRocketCardStyled = styled(ThemedButtonRocket)`
  min-height: 3rem;
`;

export const FlyBuysCardIcon = styled.img`
  width: 50px;
  height: 70px;
  transform: rotateY(0deg) rotate(345deg);
`;

export const ReceiptPreferencesButtonsRocket = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${rdsMargin.s6.top};
`;

export const LineLabelRocketStyled = styled(BodyTextRocket).attrs({
  variant: "body_300_bold",
})`
  ${rdsMargin.s6.top};
`;

export const SwitchRocketLabelDiv = styled(BodyTextRocket).attrs({
  variant: "body_300_bold",
})`
  ${rdsPadding.s3.left};
`;
