import { Layout } from "../../styled/Layout";
import {
  BodyTextRocket,
  Heading1Rocket,
  ThemedButtonRocket,
  ThemedSwitchRocket,
} from "ccp-common-ui-components";
import {
  CancelLink,
  ReceiptPreferencesButtonsRocket,
  HeaderSectionPreferencesRocket,
  HeadingContainerPreferencesRocket,
  ReceiptPreferencesContainerRocket,
  ContainerRocket,
  LineLabelRocketStyled,
  ReceiptPreferencesCardContainerRocket,
  FlyBuysCardIcon,
  ThemedButtonRocketCardStyled,
  IconCardDiv,
  ButtonCardDiv,
  BodyTextCardDiv,
  JustifyCenterCardDiv,
  LineLabelRocketCardStyled,
  SwitchRocketLabelDiv,
  StyledOL,
  RowAlignedDiv,
  LinkRocketStyled,
} from "./ReceiptPreferencesPageStyles";
import { getReturnToLink } from "../../../common/utils/RedirectionHelpers";
import {
  IconBrandColesBadge,
  IconReceiptOutline,
  IconWrapper,
} from "@coles/rocket";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import configuration from "../../../config/Configuration";
import flyBuysIcon from "../../../assets/icons/IconFlybuys.svg";
import { useIsBusinessCustomer } from "../Utils/CustomerUtils";
import { ChangeEvent, useEffect, useState } from "react";
import { AuthenticationStatus } from "../../../store/check-authentication/CheckAuthenticationSlice";
import { getCustomerProfileAction } from "../../../store/customer-profile/CustomerProfileActions";
import { getLoyaltyAccountsAction } from "../../../store/loyalty-accounts/LoyaltyAccountsActions";
import { useHistory, useParams } from "react-router-dom";
import { getCustomerProfilePreferencesAction } from "../../../store/customer-profile-preferences/CustomerProfilePreferencesActions";
import {
  instoreContent,
  mapPreferences,
  paper,
} from "../Utils/PreferencesUtils";
import { useForm } from "react-hook-form";
import { updateCustomerProfilePreferences } from "../../../api/preferences/CustomerProfilePreferencesApi";
import { ReceiptPreference } from "../../../store/customer-profile-preferences/CustomerProfilePreferencesSlice";
import { UpdateSuccessMessage } from "../UpdateSuccessMessage";
import { UpdateFailureMessage } from "../UpdateFailureMessage";
import { PageLoader } from "../../common/PageLoader";

const ReceiptPreferencesPageRocket = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isInstorePaperPreference, setIsInstorePaperPreference] =
    useState(false);

  const { brand } = useParams<{ brand?: string }>();

  const { receiptPreferences, isLoadingPreferences } = useSelector(
    (state: RootState) => state.customerProfilePreferences
  );

  const [isInstorePaperPreferencesChanged, setInstorePaperPreferencesChanged] =
    useState<boolean>(false);

  const [updatePreferencesSuccess, setUpdatePreferencesSuccess] =
    useState<boolean>(false);
  const [updatePreferencesFailed, setUpdatePreferencesFailed] =
    useState<boolean>(false);

  const customerProfile = useSelector(
    (state: RootState) => state.customerProfile
  );

  const customerProfileType = useSelector(
    (state: RootState) => state.customerProfile.profileFields.profileType
  );

  const { isLoadingCustomerProfile } = customerProfile;

  const isBusinessCustomer = useIsBusinessCustomer();

  const authenticationStatus = useSelector(
    (state: RootState) => state.authentication.status
  );
  const { flybuys, isLoadingLoyaltyAccount } = useSelector(
    (state: RootState) => state.loyaltyAccounts
  );

  const openFlybuysLink = () => {
    window.location.href = flybuys ? flybuys.linkFlybuysUrl : "";
  };

  const isFlybuysLinked = flybuys?.memberId !== "";

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.Authenticated) {
      dispatch(getCustomerProfileAction());
      dispatch(getLoyaltyAccountsAction({ returnTo: "", action: "" }));
    }
  }, [dispatch, authenticationStatus, history]);

  useEffect(() => {
    dispatch(getCustomerProfilePreferencesAction());
    if (customerProfileType === "") {
      dispatch(getCustomerProfileAction());
    }
  }, [dispatch, customerProfileType]);

  useEffect(() => {
    var InstorePaperPreferenceValue =
      receiptPreferences.find(
        (pref) =>
          pref.content === instoreContent &&
          pref.mode === paper &&
          pref.brand === brand
      )?.value ?? false;

    setIsInstorePaperPreference(!InstorePaperPreferenceValue);
  }, [receiptPreferences]);

  const handleIsInstorePaperPreferenceChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (isInstorePaperPreference !== event.target.checked) {
      setInstorePaperPreferencesChanged(true);
    }

    setIsInstorePaperPreference(event.target.checked);
  };

  const showLoader = isLoadingPreferences && isLoadingCustomerProfile;

  const cardFlyBuysNotLinkedB2C = (
    <>
      <ReceiptPreferencesCardContainerRocket>
        <JustifyCenterCardDiv>
          <IconCardDiv>
            <FlyBuysCardIcon src={flyBuysIcon} alt="alert" />
          </IconCardDiv>
          <BodyTextCardDiv>
            <LineLabelRocketCardStyled className="sentry-unmask">
              Digital receipts only
            </LineLabelRocketCardStyled>
            <BodyTextRocket>
              To enable digital receipts, link your Flybuys and Coles account.
            </BodyTextRocket>
          </BodyTextCardDiv>
          <ButtonCardDiv>
            <ThemedButtonRocketCardStyled
              data-testid="link-flybuys-button-rocket"
              className="sentry-unmask"
              variant="primary"
              size="small"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                openFlybuysLink();
              }}
            >
              Link Flybuys
            </ThemedButtonRocketCardStyled>
          </ButtonCardDiv>
        </JustifyCenterCardDiv>
      </ReceiptPreferencesCardContainerRocket>
    </>
  );

  const cardFlyBuysLinkedInStorePreference = (
    <>
      <ReceiptPreferencesCardContainerRocket>
        <JustifyCenterCardDiv>
          <IconCardDiv>
            <IconBrandColesBadge height="40" width="40" />
          </IconCardDiv>
          <BodyTextCardDiv>
            <LineLabelRocketCardStyled className="sentry-unmask">
              Digital receipts only
            </LineLabelRocketCardStyled>
            <BodyTextRocket>
              Skip printing, get digital receipts only for instore shopping.
            </BodyTextRocket>
          </BodyTextCardDiv>
          <ButtonCardDiv>
            <ThemedSwitchRocket
              checked={isInstorePaperPreference}
              onChange={handleIsInstorePaperPreferenceChange}
            />
            <SwitchRocketLabelDiv>
              {isInstorePaperPreference === true ? "On" : "Off"}
            </SwitchRocketLabelDiv>
          </ButtonCardDiv>
        </JustifyCenterCardDiv>
      </ReceiptPreferencesCardContainerRocket>
    </>
  );

  const getPayloadForInstorePreferences = () => {
    const payloadForInstorePreferences: ReceiptPreference[] = JSON.parse(
      JSON.stringify(
        receiptPreferences.filter(
          (pref) =>
            pref.brand === brand &&
            pref.content === instoreContent &&
            pref.mode === paper
        )
      )
    );
    if (isInstorePaperPreferencesChanged) {
      payloadForInstorePreferences[0].value = !isInstorePaperPreference;
    }

    return payloadForInstorePreferences;
  };

  const submitChangedPreferences = async (data: FormData) => {
    const payload = mapPreferences([
      ...(isInstorePaperPreferencesChanged
        ? getPayloadForInstorePreferences()
        : []),
    ]);

    let isPreferencesUpdateSuccessful = true;

    if (payload.length > 0) {
      try {
        isPreferencesUpdateSuccessful = await updateCustomerProfilePreferences(
          payload
        );
        if (isPreferencesUpdateSuccessful) {
          setUpdatePreferencesSuccess(true);
          setUpdatePreferencesFailed(false);
        }
      } catch {
        setUpdatePreferencesSuccess(false);
        setUpdatePreferencesFailed(true);
      }
    } else {
      setUpdatePreferencesSuccess(true);
      setUpdatePreferencesFailed(false);
    }
  };

  const updateSuccessMessage = (
    <UpdateSuccessMessage text="Your receipt preferences have been updated." />
  );

  const updateErrorMessage = (
    <UpdateFailureMessage>
      An error has occurred. Your receipt preferences have not been updated.
    </UpdateFailureMessage>
  );

  const { handleSubmit } = useForm<FormData>();

  return (
    <Layout>
      <ContainerRocket>
        <PageLoader isShown={showLoader} />
        {!showLoader && (
          <>
            <HeaderSectionPreferencesRocket data-testid="receipt-preference-header">
              <RowAlignedDiv>
                <IconWrapper
                  icon={<IconReceiptOutline height="30" width="30" />}
                  indicator={""}
                />
                <HeadingContainerPreferencesRocket>
                  <Heading1Rocket data-testid="preferences-heading">
                    Receipt preferences
                  </Heading1Rocket>
                </HeadingContainerPreferencesRocket>
              </RowAlignedDiv>
            </HeaderSectionPreferencesRocket>

            <ReceiptPreferencesContainerRocket>
              {updatePreferencesSuccess &&
                !updatePreferencesFailed &&
                updateSuccessMessage}
              {!updatePreferencesSuccess &&
                updatePreferencesFailed &&
                updateErrorMessage}
              <form
                data-testid="manage-my-receipt-preferences-form"
                onSubmit={handleSubmit(submitChangedPreferences)}
                noValidate
              >
                <>
                  <LineLabelRocketStyled className="sentry-unmask">
                    Go paperless with Coles barcode
                  </LineLabelRocketStyled>
                  <StyledOL className="sentry-unmask">
                    <BodyTextRocket>
                      <li>Turn on Digital receipts only</li>
                      <li>
                        Scan your Coles barcode each time you checkout instore
                      </li>
                      <li>
                        To access digital receipts, visit{" "}
                        <LinkRocketStyled
                          tabIndex={0}
                          id="order-and-purchase"
                          data-testid="order-and-purchase"
                          label="Orders & purchases"
                          variant="inline"
                          size="small"
                          href={configuration.cuspActiveOrdersUrl}
                          target="_blank"
                        />
                      </li>
                    </BodyTextRocket>
                  </StyledOL>

                  {!isLoadingCustomerProfile && isBusinessCustomer && (
                    <LineLabelRocketCardStyled className="sentry-unmask">
                      Instore
                    </LineLabelRocketCardStyled>
                  )}

                  {!isLoadingCustomerProfile &&
                    !isLoadingLoyaltyAccount &&
                    !flybuys.hasError &&
                    !isFlybuysLinked &&
                    cardFlyBuysNotLinkedB2C}

                  {!showLoader &&
                    (flybuys.hasError || isFlybuysLinked) &&
                    cardFlyBuysLinkedInStorePreference}

                  <ReceiptPreferencesButtonsRocket>
                    <ThemedButtonRocket
                      data-testid="save-button-rocket"
                      className="sentry-unmask"
                    >
                      Save
                    </ThemedButtonRocket>
                    <CancelLink
                      tabIndex={0}
                      href={getReturnToLink()}
                      className="sentry-unmask"
                    >
                      Cancel
                    </CancelLink>
                  </ReceiptPreferencesButtonsRocket>
                </>
              </form>
            </ReceiptPreferencesContainerRocket>
          </>
        )}
      </ContainerRocket>
    </Layout>
  );
};

export default ReceiptPreferencesPageRocket;
