import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum VerificationChannel {
  Unknown,
  Email,
  Sms,
}

export type SendCodeArgs = {
  mobile: string;
};

export type VerifyCodeArgs = {
  verificationCode: string;
  channel: VerificationChannel;
};

export interface VerifyMobileNumberState {
  isSendingCode: boolean;
  isCodeSent: boolean;
  verificationErrorPageMessage?: string;
  isVerifyingCode: boolean;
  isVerifyCodeSent: boolean;
  verifyCodeErrorMessage?: string;
  isMobileNumberDeletionAllowed: boolean;
  isDeletingMobile: boolean;
  deleteMobileErrorMessage?: string;
  newMfaPreference?: string;
  isDeleteMobileSuccess?: boolean;
}

export const initialState: VerifyMobileNumberState = {
  isSendingCode: false,
  isCodeSent: false,
  isVerifyingCode: false,
  isVerifyCodeSent: false,
  isMobileNumberDeletionAllowed: true,
  isDeletingMobile: false,
  isDeleteMobileSuccess: undefined,
};

interface VerifyCodeFailedPayload {
  errorMessage: string;
  canRetry: boolean;
}

const verifyMobileNumberSlice = createSlice({
  name: "verifyMobileNumber",
  initialState,
  reducers: {
    addEditMobileNumberPageLoaded: (state) => {
      state.deleteMobileErrorMessage = undefined;
      state.isDeleteMobileSuccess = undefined;
    },
    sendCodeAction: (state, action: PayloadAction<SendCodeArgs>) => {
      state.isSendingCode = true;
      state.isCodeSent = false;

      state.verificationErrorPageMessage = undefined;
    },
    sendCodeSuccess: (state) => {
      state.isCodeSent = true;
      state.isSendingCode = false;

      state.verificationErrorPageMessage = undefined;
    },
    sendCodeFailed: (state, action: PayloadAction<string>) => {
      state.isCodeSent = false;
      state.isSendingCode = false;

      state.verificationErrorPageMessage = action.payload;
    },
    verifyCodeAction: (state, action: PayloadAction<VerifyCodeArgs>) => {
      state.isVerifyingCode = true;
      state.isVerifyCodeSent = false;
      state.verifyCodeErrorMessage = undefined;
    },
    verifyCodeFailed: (
      state,
      action: PayloadAction<VerifyCodeFailedPayload>
    ) => {
      state.isVerifyCodeSent = false;
      state.isVerifyingCode = false;
      if (action.payload.canRetry) {
        state.verifyCodeErrorMessage = action.payload.errorMessage;
      } else {
        state.verificationErrorPageMessage = action.payload.errorMessage;
      }
    },
    resetVerifyCodeState: (state) => {
      state.isVerifyCodeSent = false;
      state.isVerifyingCode = false;
      state.verifyCodeErrorMessage = undefined;
    },
    deleteMobileNumber: (state) => {
      state.isDeletingMobile = true;
    },
    deleteMobileSuccess: (state) => {
      state.isDeletingMobile = false;
      state.deleteMobileErrorMessage = undefined;
      state.isDeleteMobileSuccess = true;
    },
    deleteMobileFailed: (state, action: PayloadAction<string>) => {
      state.isDeletingMobile = false;
      state.deleteMobileErrorMessage = action.payload;
      state.isDeleteMobileSuccess = false;
    },
    startMfaPreferenceFlow: (state, action: PayloadAction<string>) => {
      state.isMobileNumberDeletionAllowed = false;
      state.newMfaPreference = action.payload;
    },
    endMfaPreferenceFlow: (state) => {
      state.isMobileNumberDeletionAllowed = true;
      state.newMfaPreference = undefined;
    },
  },
});

export const {
  addEditMobileNumberPageLoaded,
  sendCodeAction,
  sendCodeSuccess,
  sendCodeFailed,
  verifyCodeAction,
  verifyCodeFailed,
  resetVerifyCodeState,
  deleteMobileNumber,
  deleteMobileSuccess,
  deleteMobileFailed,
  startMfaPreferenceFlow,
  endMfaPreferenceFlow,
} = verifyMobileNumberSlice.actions;

export const verifyMobileNumberReducer = verifyMobileNumberSlice.reducer;
