import styled from "styled-components";

import { screenSize } from "../../../common/styles/ScreenSizes";
import { rdsMargin, rdsPadding } from "@coles/rocket";
import { BodyTextRocket, Heading2Rocket } from "ccp-common-ui-components";

export const AddAddressContainerRocket = styled.div`
  display: flex;
  ${rdsPadding.s3.top};
  ${rdsPadding.s3.bottom};
  ${rdsMargin.s3.bottom};
  width: 100%;
`;

export const AddAddressFormContainerRocket = styled.form`
  width: 100%;
  @media ${screenSize.mobile} {
    ${rdsMargin.none.left};
  }
`;

export const AddressFormColumnRocket = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AddressFormColumnContainerRocket = styled.div`
  display: flex;
  flex-direction: column;
  }
`;

export const AddressFormColumnContentRocket = styled.div`
  ${rdsPadding.s5.left};
  ${rdsPadding.s4.right};
  ${rdsMargin.s3.bottom};
`;

export const AddressFormHeaderRocket = styled(Heading2Rocket).attrs({})`
  ${rdsMargin.s3.top};
  ${rdsMargin.none.right};
  ${rdsMargin.s6.bottom};
  ${rdsMargin.none.left};

  @media ${screenSize.mobile} {
    line-height: 32px;
`;

export const RocketButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const RocketButtonLeftContainer = styled.div`
  justify-self: left;
`;
export const RocketButtonRightContainer = styled.div`
  justify-self: right;
`;

export const RocketIconSettingNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: centre;
  margin-left: -20px;
  @media ${screenSize.mobile} {
    flex: 1 0 100%;
  }
`;

export const MapIconRocketContainer = styled.div`
  width: 35px;
`;

export const AddressLineSettingNameRocket = styled(BodyTextRocket).attrs({
  variant: "body_300_bold",
})``;
