import { ThemedButtonRocket } from "ccp-common-ui-components";
import styled from "styled-components";
import { screenSize } from "./styles/ScreenSizes";
import { useMediaPredicate } from "react-media-hook";
import { redirect } from "./utils/NavigationHelpers";
import configuration from "../config/Configuration";
import { useSelector } from "react-redux";
import { RootState } from "../store/Store";
import { AuthenticationStatus } from "../store/check-authentication/CheckAuthenticationSlice";
import { useHistory, useLocation } from "react-router-dom";
import { rdsMargin, rdsPadding } from "@coles/rocket";

const BackButtonTextElement = styled.span`
  &:before {
    content: "\\2039";
    transform: scale(1.5);
    display: inline-block;
    ${rdsMargin.s2.right};
    bottom: 3px;
    position: relative;
  }
`;

const WebBackButton = styled(ThemedButtonRocket)`
  ${rdsPadding.none.all};
`;

const MobileBackButton = styled(ThemedButtonRocket)`
  ${rdsPadding.none.all};
`;

export interface BackButtonContainerProps {
  returnTo?: string | null;
}

export function BackButtonContainerRocket({
  returnTo,
}: BackButtonContainerProps) {
  const isMobileView = useMediaPredicate(screenSize.mobile);
  const location = useLocation();
  const history = useHistory();
  const buttonText = "Back";

  const authenticationStatus = useSelector(
    (state: RootState) => state.authentication.status
  );
  const isUserAuthenticated =
    authenticationStatus === AuthenticationStatus.Authenticated;

  const isReturnUrlInAllowedList = configuration.allowedReturnUrls?.some(
    (x) => x.toLowerCase() === returnTo?.toLowerCase()
  );

  const backButtonHandler = () => {
    if (returnTo && isReturnUrlInAllowedList) {
      redirect(returnTo);
    } else {
      history.goBack();
    }
  };

  const mobileBackButton = (
    <MobileBackButton
      data-testid="back-button-rocket"
      onClick={() => backButtonHandler()}
      variant="textPrimary"
      noLinkHover
    >
      <BackButtonTextElement>{buttonText}</BackButtonTextElement>
    </MobileBackButton>
  );

  const webBackButton = (
    <WebBackButton
      data-testid="back-button-rocket"
      onClick={() => backButtonHandler()}
      variant="textPrimary"
      noLinkHover
    >
      <BackButtonTextElement>{buttonText}</BackButtonTextElement>
    </WebBackButton>
  );

  if (!isUserAuthenticated || location.pathname === "/") return null;

  return isMobileView ? mobileBackButton : webBackButton;
}
