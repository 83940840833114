import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/Store";
import { SettingLink } from "../../styled/SettingLink";
import {
  setIsUnlinkModelOpen,
  setTeamMemberNumberUnlinkSuccess,
} from "../../../store/team-member-number/TeamMemberNumberSlice";
import { SettingButton } from "../../styled/SettingButton";
import { InlineLoader } from "../../../common/loader/InlineLoader";
import { SettingName } from "../../styled/SettingName";
import { SettingLine } from "../../styled/SettingLine";
import { SpinnerContainer } from "../../styled/SpinnerContainer";
import {
  Container,
  LoadingText,
  MyColesImage,
  teamMemberNumberImg,
  ValueMessage,
} from "./TeamMemberProfileStyles";
import { UpdateSuccessMessage } from "../UpdateSuccessMessage";
import { UpdateFailureMessage } from "../UpdateFailureMessage";
import { useEffect } from "react";
import { AuthenticationStatus } from "../../../store/check-authentication/CheckAuthenticationSlice";
import MyColesIcon from "../../../assets/icons/mycoles.svg";
import { Skeleton } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import useRocketEnabled from "../../../hooks/useRocketEnabled";
import { Settings } from "../ProfileSettings";
import ProfileSettingRocket, {
  SettingFieldStatus,
} from "../ProfileSettingRocket";
import { ThemedMessageRocket } from "ccp-common-ui-components";

const theme = createTheme({});

const TeamMemberProfile = () => {
  const dispatch = useDispatch();
  const isRocketEnabled = useRocketEnabled();

  const authenticationStatus = useSelector(
    (state: RootState) => state.authentication.status
  );

  const { isLoadingLoyaltyAccount } = useSelector(
    (state: RootState) => state.loyaltyAccounts
  );

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.Authenticated) {
      dispatch(setTeamMemberNumberUnlinkSuccess(undefined));
    }
  }, [dispatch, authenticationStatus]);

  const { teamMember } = useSelector(
    (state: RootState) => state.loyaltyAccounts
  );

  const {
    isUnlinkRequestLoading,
    isTeamMemberNumberUnlinkSuccess,
    isTeamMemberNumberLinkSuccess,
  } = useSelector((state: RootState) => state.teamMemberNumber);

  const openUnlinkModel = () => {
    dispatch(setIsUnlinkModelOpen(true));
  };

  const isTeamMemberNumberLinked = teamMember && teamMember.memberId !== "";

  const displayText =
    "Are you a Coles team member? Link your mycoles card to receive a discount on every shop.";

  const errorSettingLine = (
    <SettingLine>
      <SettingName>
        <MyColesImage src={MyColesIcon} alt="My Coles" />
      </SettingName>
      <ValueMessage>Something went wrong. Please try again later.</ValueMessage>
    </SettingLine>
  );

  const unlinkRequestLoading = (
    <SettingLine>
      <SettingName>
        <MyColesImage src={MyColesIcon} alt="My Coles" />
      </SettingName>
      <ValueMessage>{teamMember ? teamMember.memberId : ""}</ValueMessage>
      <SpinnerContainer>
        <InlineLoader />
        <LoadingText>Updating...</LoadingText>
      </SpinnerContainer>
    </SettingLine>
  );

  const successUnlinkMessage = (
    <UpdateSuccessMessage text="Your mycoles card has been successfully unlinked." />
  );

  const failureUnlinkMessage = (
    <UpdateFailureMessage>
      Your mycoles card could not be unlinked. Please try again.
    </UpdateFailureMessage>
  );

  const SkeletonGroup = (
    <SettingLine>
      <Skeleton
        data-testid={`team-member-number-linked-image`}
        variant="rectangular"
        width={teamMemberNumberImg.width}
        height={teamMemberNumberImg.height}
      />
      <ValueMessage>
        <Skeleton
          style={{ marginLeft: "24px" }}
          data-testid={`team-member-number-linked-message`}
          variant="text"
        />
      </ValueMessage>
    </SettingLine>
  );

  const TeamMemberNumberLinked = (
    <SettingLine>
      <SettingName>
        <MyColesImage src={MyColesIcon} alt="My Coles" />
      </SettingName>
      <ValueMessage>
        {teamMember.memberId}

        {isTeamMemberNumberUnlinkSuccess !== undefined &&
          !isTeamMemberNumberUnlinkSuccess &&
          failureUnlinkMessage}
      </ValueMessage>
      <SettingLink>
        <SettingButton
          data-testid="team-member-unlink-page"
          onClick={openUnlinkModel}
        >
          Unlink card
        </SettingButton>
      </SettingLink>
    </SettingLine>
  );

  const TeamMemberNumberUnlinked = (
    <SettingLine>
      <SettingName>
        <MyColesImage src={MyColesIcon} alt="My Coles" />
      </SettingName>

      <ValueMessage>
        {displayText}
        {isTeamMemberNumberUnlinkSuccess && successUnlinkMessage}
      </ValueMessage>
      <SettingLink>
        <Link data-testid="team-member-link-page" to="/team-member-link-page">
          Link mycoles card
        </Link>
      </SettingLink>
    </SettingLine>
  );

  const ErrorMessageRocket = () => {
    return (
      <ThemedMessageRocket
        type="error"
        text={"Something went wrong. Please try again later."}
      />
    );
  };
  const UnlinkSuccessfulMessage = () => {
    return (
      <ThemedMessageRocket
        type="success"
        text="Your mycoles card has been successfully unlinked."
      />
    );
  };
  const UnlinkUnsuccessfulMessage = () => {
    return (
      <ThemedMessageRocket
        type="error"
        text="Your mycoles card could not be unlinked. Please try again."
      />
    );
  };
  const LinkSuccessfulMessage = () => {
    return <ThemedMessageRocket type="success" text="mycoles card linked" />;
  };
  const LinkUnsuccessfulMessage = () => {
    return (
      <ThemedMessageRocket
        type="error"
        text="Your mycoles card could not be linked. Please try again."
      />
    );
  };

  const getMessageComponent = () => {
    if (teamMember.hasError) {
      return ErrorMessageRocket;
    }
    if (isTeamMemberNumberUnlinkSuccess !== undefined) {
      return isTeamMemberNumberUnlinkSuccess
        ? UnlinkSuccessfulMessage
        : UnlinkUnsuccessfulMessage;
    }
    if (isTeamMemberNumberLinkSuccess !== undefined) {
      return isTeamMemberNumberLinkSuccess
        ? LinkSuccessfulMessage
        : LinkUnsuccessfulMessage;
    }
    return undefined;
  };

  return isRocketEnabled ? (
    isTeamMemberNumberLinked ? (
      <Settings>
        <ProfileSettingRocket
          name="mycoles"
          handleEdit={openUnlinkModel}
          id="mycoles-unlink"
          link="Unlink mycoles"
          value={teamMember.hasError ? " " : teamMember.memberId}
          linkEnabled
          status={
            isUnlinkRequestLoading
              ? SettingFieldStatus.PendingUpdate
              : SettingFieldStatus.Readable
          }
          MessageComponent={getMessageComponent()}
        />
      </Settings>
    ) : (
      <Settings>
        <ProfileSettingRocket
          name="mycoles"
          editLink="/team-member-link-page"
          id="mycoles-link"
          link="Link mycoles"
          value={teamMember.hasError ? " " : "No mycoles is linked."}
          linkEnabled
          MessageComponent={getMessageComponent()}
        />
      </Settings>
    )
  ) : (
    <MuiThemeProvider theme={theme}>
      <Container data-testid="team-member">
        {isLoadingLoyaltyAccount && SkeletonGroup}
        {!isLoadingLoyaltyAccount && teamMember.hasError && errorSettingLine}
        {!isLoadingLoyaltyAccount &&
          !teamMember.hasError &&
          isUnlinkRequestLoading &&
          unlinkRequestLoading}
        {!isLoadingLoyaltyAccount &&
          !teamMember.hasError &&
          !isUnlinkRequestLoading &&
          isTeamMemberNumberLinked &&
          TeamMemberNumberLinked}
        {!isLoadingLoyaltyAccount &&
          !teamMember.hasError &&
          !isUnlinkRequestLoading &&
          !isTeamMemberNumberLinked &&
          TeamMemberNumberUnlinked}
      </Container>
    </MuiThemeProvider>
  );
};

export default TeamMemberProfile;
