import React from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SettingLine } from "../../styled/SettingLine";
import { SettingName } from "../../styled/SettingName";
import { RootState } from "../../../store/Store";

import { SettingLink } from "../../styled/SettingLink";
import { MobileNumberDisplay } from "./MobileNumberDisplay";
import editIcon from "../../../assets/icons/edit.svg";
import styled from "styled-components";
import { useFeatureFlags } from "../../../common/feature-flags/UseFeatureFlags";
import useRocketEnabled from "../../../hooks/useRocketEnabled";
import ProfileSettingRocket from "../ProfileSettingRocket";
import { ThemedMessageRocket } from "ccp-common-ui-components";
import { UpdateSuccessMessage } from "../UpdateSuccessMessage";
import { UpdateFailureMessage } from "../UpdateFailureMessage";

const EditIcon = styled.img`
  content: initial;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 3px 0 0;
`;

export function MobileNumberSetting() {
  const { isBcpFeatureEnabled } = useFeatureFlags();
  const isRocketEnabled = useRocketEnabled();
  const { mobile: originalMobileNumber } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );
  const isDeleteMobileSuccess = useSelector(
    (state: RootState) => state.verifyMobileNumber.isDeleteMobileSuccess
  );

  const buttonText = originalMobileNumber
    ? "Edit mobile number"
    : "Add mobile number";

  const DeleteSuccessfulMessage = () => {
    return (
      <ThemedMessageRocket
        type="success"
        text="Your mobile number has been successfully deleted."
      />
    );
  };
  const DeleteUnsuccessfulMessage = () => {
    return (
      <ThemedMessageRocket
        type="error"
        text="Your mobile number could not be deleted. Please try again later."
      />
    );
  };

  /*    const DeleteMobileStatusMessage = () => {
          if (isDeleteMobileSuccess === undefined) return <></>;
          return isDeleteMobileSuccess ? (
            <UpdateSuccessMessage text="Your mobile number has been successfully deleted." />
          ) : (
            <UpdateFailureMessage>
              Something went wrong and we can't delete your mobile number right now. Please
              try again later.
            </UpdateFailureMessage>
          );
        };
*/
  const getMessageComponent = () => {
    if (isDeleteMobileSuccess !== undefined) {
      return isDeleteMobileSuccess
        ? DeleteSuccessfulMessage
        : DeleteUnsuccessfulMessage;
    }
    return undefined;
  };

  return isRocketEnabled ? (
    <ProfileSettingRocket
      name="Mobile number"
      value={originalMobileNumber}
      editLink="/add-edit-mobile"
      id="mobile-number"
      linkEnabled={!isBcpFeatureEnabled}
      link={isBcpFeatureEnabled ? "" : originalMobileNumber ? "Edit" : "Add"}
      MessageComponent={getMessageComponent()}
    />
  ) : (
    <SettingLine>
      <SettingName>Mobile number:</SettingName>
      <MobileNumberDisplay />
      {!isBcpFeatureEnabled && (
        <SettingLink>
          {originalMobileNumber && (
            <EditIcon
              data-testid="edit-icon"
              src={editIcon}
              alt=""
              aria-hidden
            />
          )}
          <Link data-testid="add-edit-mobile-link" to="/add-edit-mobile">
            {buttonText}
          </Link>
        </SettingLink>
      )}
    </SettingLine>
  );
}
