import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import { RootState } from "../../../store/Store";

import debounce from "lodash.debounce";

import {
  saveAddress,
  setFormVisibility,
  getMatchingAddressSuggestions,
  setMatchingAddresses,
  editAddress,
  setDeleteAddressModalVisibility,
  setAddressTobeDeleted,
} from "../../../store/add-address/AddAddressSlice";

import {
  AddAddressContainerRocket,
  AddAddressFormContainerRocket,
  AddressFormColumnContentRocket,
  AddressFormColumnContainerRocket,
  AddressFormColumnRocket,
  RocketButtonsContainer,
  RocketButtonLeftContainer,
  RocketButtonRightContainer,
  RocketIconSettingNameContainer,
  AddressFormHeaderRocket,
  MapIconRocketContainer,
  AddressLineSettingNameRocket,
} from "./AddressStylesRocket";

import { AddAddressInputRocket } from "./AddAddressInputRocket";
import {
  EditAddressRequest,
  SaveAddressRequest,
} from "../../../api/address/AddressesApi";

import {
  isValidHomePhone,
  removeAllSpaces,
} from "../../profile/Utils/PhoneUtils";

import {
  ThemedButtonRocket,
  AutocompleteInputFieldRocket,
  AutoSuggestOption,
  MapPinOutlineIconRocket,
} from "ccp-common-ui-components";
import { CustomerProfileAddressFields } from "../../../store/customer-profile-address/CustomerProfileAddressSlice";

export type AddAddressFormRocketData = {
  nickName: string;
  globalAddressKey: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

interface AddAddressFormRocketProps {
  address: CustomerProfileAddressFields | null;
  setEditAddress: (arg0: CustomerProfileAddressFields | null) => void | null;
}

export const isNickNameUniqueExceptSelf = (
  addresses: CustomerProfileAddressFields[],
  newNickname: string,
  oldNickname: string
) => {
  return !addresses
    .filter((a) => a.nickname !== oldNickname)
    .some((a) => a.nickname === newNickname);
};

const emailRegex =
  /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,100})$/;
const wordCharsRegex = /^[a-zA-Z-' ]*$/;

export default function AddAddressFormRocket(props: AddAddressFormRocketProps) {
  const AUTOCOMPLETE_INPUT_LIMIT = 7;
  const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false);
  const [autocompleteInput, setAutocompleteInput] = useState<string>("");
  const [autocompleteValue, setAutocompleteValue] =
    useState<AutoSuggestOption | null>(null);

  const setEditAddress = props.setEditAddress;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    getFieldState,
    formState: { isSubmitSuccessful, errors },
    trigger,
    control,
  } = useForm<AddAddressFormRocketData>();

  useEffect(() => {
    if (props.address) {
      const aggregatedAddress = [
        props.address.addressLine1,
        props.address.addressLine2,
        props.address.addressLine3,
        props.address.city,
        props.address.state,
        props.address.postcode,
      ]
        .filter(Boolean)
        .join(", ");

      setAutocompleteInput(aggregatedAddress);
      setAutocompleteValue({
        value: "placeholderString",
        label: aggregatedAddress,
      });
    }
  }, [props.address]);

  const [userTriedToSubmit, setUserTriedToSubmit] = useState(false);

  const revalidate = (
    name:
      | "nickName"
      | "firstName"
      | "lastName"
      | "email"
      | "phone"
      | "globalAddressKey"
  ) => {
    if (userTriedToSubmit) {
      trigger(name);
    }
  };

  const onAutocompleteInput = (newValue: string, reason: string) => {
    setAutocompleteInput(newValue);
    if (newValue.length >= AUTOCOMPLETE_INPUT_LIMIT) {
      debouncedGetAddressSuggestions();
    }
  };

  const getAddressSuggestions = () => {
    dispatch(getMatchingAddressSuggestions({ addressText: autocompleteInput }));
  };

  const clearAddressSuggestions = useCallback(() => {
    setAutocompleteInput("");
    setAutocompleteValue(null);
    setValue("globalAddressKey", "");
    dispatch(setMatchingAddresses([]));
  }, [dispatch, setValue]);

  const mapSearchResultsToOptions = (
    searchResults: any[]
  ): AutoSuggestOption[] => {
    return searchResults.map((result) => ({
      value: result.id,
      label: result.label,
    }));
  };

  const debouncedGetAddressSuggestions = debounce(getAddressSuggestions, 300);

  const nickNameInputName = "nickName";
  const recipientFirstNameInputName = "firstName";
  const recipientLastNameInputName = "lastName";
  const emailInputName = "email";
  const phoneInputName = "phone";

  const { showAddAddressForm, matchingAddresses } = useSelector(
    (state: RootState) => state.addAddress
  );

  const nickname =
    props.address && props.address.nickname ? props.address.nickname : "";

  const { firstName, lastName, mobile, homePhone, email } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );

  const recipientFirstName =
    props.address && props.address.recipientFirstName
      ? props.address.recipientFirstName
      : firstName;

  const recipientLastName =
    props.address && props.address.recipientLastName
      ? props.address.recipientLastName
      : lastName;

  const editEmail = props.address?.contacts.find(
    (contact) => contact.key === "email"
  )?.value;

  const customerEmail = editEmail ?? email;

  const editPhoneNumber = props.address?.contacts.find(
    (contact) => contact.key === "phone"
  )?.value;

  const customerPhoneNumber = mobile ?? homePhone;

  const phoneNumber = editPhoneNumber ?? customerPhoneNumber;

  const addresses = useSelector(
    (state: RootState) => state.customerProfileAddress.addresses
  );

  const isNicknameUnique = useCallback(
    (newNickname: string) => {
      return !addresses.some((a) => a.nickname === newNickname);
    },
    [addresses]
  );

  useEffect(() => {
    register(nickNameInputName, {
      required: "Please enter an address name.",
      validate: (value) =>
        (props.address
          ? isNickNameUniqueExceptSelf(addresses, value, props.address.nickname)
          : isNicknameUnique(value)) ||
        "This address name has already been used. Please try a different name.",
    });
  }, [nickname, isNicknameUnique, register, addresses, props.address]);

  useEffect(() => {
    register(recipientFirstNameInputName, {
      required: "Please enter recipient first name.",
      pattern: {
        value: wordCharsRegex,
        message:
          "Recipient first name cannot include special characters or symbols. Please try again.",
      },
    });
  }, [recipientFirstName, register]);

  useEffect(() => {
    register(recipientLastNameInputName, {
      required: "Please enter recipient last name.",
      pattern: {
        value: wordCharsRegex,
        message:
          "Recipient last name cannot include special characters or symbols. Please try again.",
      },
    });
  }, [recipientLastName, register]);

  useEffect(() => {
    register(emailInputName, {
      required: "Please enter a valid email address.",
      pattern: {
        value: emailRegex,
        message: "Please enter a valid email address.",
      },
    });
  }, [customerEmail, register]);

  useEffect(() => {
    register(phoneInputName, {
      required: "Please enter a valid phone number. For example: 0411 222 333",
      validate: (value) =>
        typeof isValidHomePhone(value) !== "string" ||
        "Please enter a valid phone number. For example: 0411 222 333",
    });
  }, [phoneNumber, register]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      setUserTriedToSubmit(false);
      clearAddressSuggestions();
      clearErrors();
      reset();
    }
  }, [isSubmitSuccessful, clearAddressSuggestions, clearErrors, reset]);

  const onSubmit = (data: AddAddressFormRocketData) => {
    setUserTriedToSubmit(true);

    if (props.address && props.address.id) {
      const editRequest: EditAddressRequest = {
        Id: props.address?.id,
        CustomerNotes: "",
        Nickname: data.nickName,
        GlobalAddressKey: data.globalAddressKey,
        RecipientFirstName: data.firstName,
        RecipientLastName: data.lastName,
        Email: data.email,
        Phone: removeAllSpaces(data.phone),
      };

      clearErrors();
      dispatch(editAddress(editRequest));
      dispatch(setFormVisibility(false));
    } else {
      const addRequest: SaveAddressRequest = {
        Nickname: data.nickName,
        GlobalAddressKey: data.globalAddressKey,
        RecipientFirstName: data.firstName,
        RecipientLastName: data.lastName,
        Email: data.email,
        Phone: removeAllSpaces(data.phone),
      };

      clearErrors();
      dispatch(saveAddress(addRequest));
      dispatch(setFormVisibility(false));
    }
  };

  const onAutocompleteChange = (
    event: React.SyntheticEvent,
    value: unknown,
    reason: string
  ) => {
    if (value == null) {
      setValue("globalAddressKey", "");
      revalidate("globalAddressKey");
    }

    if (value && typeof value !== "string") {
      const option = value as AutoSuggestOption | null;
      setAutocompleteValue(option && option.value ? option : null);
      setValue("globalAddressKey", option ? option!.value : "");
      revalidate("globalAddressKey");
    }
  };

  const onAutocompleteInputChange = (
    event: React.SyntheticEvent,
    newValue: string,
    reason: string
  ) => {
    if (reason === "clear") {
      clearAddressSuggestions();
      setValue("globalAddressKey", "");
      revalidate("globalAddressKey");
    }
    onAutocompleteInput(newValue, reason);
    console.log(
      "getFieldState globalAddressKey = ",
      getFieldState("globalAddressKey")
    );
  };

  const isAutocompleteOptionEqualToValue = (option: unknown, value: any) => {
    const autoSuggestOption = option as AutoSuggestOption;
    return (
      autoSuggestOption.value === value.value ||
      autoSuggestOption.value === value.id
    );
  };

  const onAutocompleteClose = (event: React.SyntheticEvent) => {
    setAutocompleteOpen(false);
  };

  const onAutocompleteOpen = (event: React.SyntheticEvent) => {
    if (
      (event.type === "mousedown" || event.type === "change") &&
      autocompleteInput.length >= AUTOCOMPLETE_INPUT_LIMIT
    ) {
      setAutocompleteOpen(true);
    }
  };

  const onSaveButtonClick = () => {
    setUserTriedToSubmit(true);
  };

  const onCancelButtonClick = () => {
    clearAddressSuggestions();
    clearErrors();
    dispatch(setFormVisibility(false));
    setEditAddress(null);
  };

  const onDeleteButtonClick = () => {
    dispatch(setDeleteAddressModalVisibility(true));
    if (props.address) {
      dispatch(
        setAddressTobeDeleted({
          Id: props.address?.id,
          Nickname: props.address?.nickname,
        })
      );
    }
  };

  const formHeaderText = `${props.address ? "Edit" : "Add"} a delivery address`;

  return (
    <>
      {showAddAddressForm && (
        <AddAddressContainerRocket>
          <AddAddressFormContainerRocket
            onSubmit={handleSubmit(onSubmit)}
            data-testid="add-address-form"
          >
            <AddressFormHeaderRocket>{formHeaderText}</AddressFormHeaderRocket>
            <AddressFormColumnContainerRocket>
              <AddressFormColumnRocket>
                <AddressFormColumnContentRocket>
                  <RocketIconSettingNameContainer>
                    <MapIconRocketContainer>
                      <MapPinOutlineIconRocket size={24} />
                    </MapIconRocketContainer>
                    <AddressLineSettingNameRocket>
                      Address name
                    </AddressLineSettingNameRocket>
                  </RocketIconSettingNameContainer>
                  <AddAddressInputRocket
                    id="nick-name"
                    name={nickNameInputName}
                    value={nickname}
                    defaultValue={nickname}
                    setValue={setValue}
                    errorMessage={errors.nickName?.message}
                    errorTextId="nick-name-error"
                    revalidate={revalidate}
                  />
                </AddressFormColumnContentRocket>
                <AddressFormColumnContentRocket>
                  <AddressLineSettingNameRocket htmlFor="first-name">
                    Recipient first name
                  </AddressLineSettingNameRocket>
                  <AddAddressInputRocket
                    id="first-name"
                    name={recipientFirstNameInputName}
                    value={recipientFirstName}
                    defaultValue={recipientFirstName}
                    setValue={setValue}
                    errorMessage={errors.firstName?.message}
                    errorTextId="first-name-error"
                    revalidate={revalidate}
                  />
                </AddressFormColumnContentRocket>
                <AddressFormColumnContentRocket>
                  <AddressLineSettingNameRocket htmlFor="last-name">
                    Recipient last name
                  </AddressLineSettingNameRocket>
                  <AddAddressInputRocket
                    id="last-name"
                    name={recipientLastNameInputName}
                    value={recipientLastName}
                    defaultValue={recipientLastName}
                    setValue={setValue}
                    errorMessage={errors.lastName?.message}
                    errorTextId="last-name-error"
                    revalidate={revalidate}
                  />
                </AddressFormColumnContentRocket>
              </AddressFormColumnRocket>
              <AddressFormColumnRocket>
                <AddressFormColumnContentRocket>
                  <Controller
                    name="globalAddressKey"
                    control={control}
                    render={() => (
                      <AutocompleteInputFieldRocket
                        options={mapSearchResultsToOptions(
                          matchingAddresses || []
                        )}
                        onChange={onAutocompleteChange}
                        filterOptions={(x) => x}
                        onInputChange={onAutocompleteInputChange}
                        inputValue={autocompleteInput}
                        value={autocompleteValue}
                        noOptionsText="No address matches found"
                        open={autocompleteOpen}
                        isOptionEqualToValue={isAutocompleteOptionEqualToValue}
                        labelText="Delivery address:"
                        descriptionText=""
                        hasError={!!errors.globalAddressKey}
                        errorText={errors.globalAddressKey?.message}
                        onClose={onAutocompleteClose}
                        onOpen={onAutocompleteOpen}
                      />
                    )}
                    rules={
                      props.address
                        ? {}
                        : { required: "Please select delivery address." }
                    }
                    defaultValue=""
                  />
                </AddressFormColumnContentRocket>
                <AddressFormColumnContentRocket>
                  <AddressLineSettingNameRocket htmlFor="email">
                    Email
                  </AddressLineSettingNameRocket>
                  <AddAddressInputRocket
                    id="email"
                    name={emailInputName}
                    value={customerEmail}
                    defaultValue={customerEmail}
                    setValue={setValue}
                    errorMessage={errors.email?.message}
                    errorTextId="email-error"
                    revalidate={revalidate}
                  />
                </AddressFormColumnContentRocket>
                <AddressFormColumnContentRocket>
                  <AddressLineSettingNameRocket htmlFor="phone">
                    Phone number
                  </AddressLineSettingNameRocket>
                  <AddAddressInputRocket
                    id="phone"
                    name={phoneInputName}
                    value={phoneNumber}
                    defaultValue={phoneNumber}
                    setValue={setValue}
                    errorMessage={errors.phone?.message}
                    errorTextId="phone-error"
                    revalidate={revalidate}
                  />
                </AddressFormColumnContentRocket>
              </AddressFormColumnRocket>
            </AddressFormColumnContainerRocket>
            <RocketButtonsContainer>
              <RocketButtonLeftContainer>
                <ThemedButtonRocket
                  variant="primary"
                  id="save-button"
                  data-testid="save-button"
                  label="Save"
                  onClick={onSaveButtonClick}
                />

                <ThemedButtonRocket
                  variant="textSecondary"
                  noLinkHover
                  id="cancel-button"
                  data-testid="cancel-button"
                  label="Cancel"
                  onClick={onCancelButtonClick}
                />
              </RocketButtonLeftContainer>
              <RocketButtonRightContainer>
                {props.address && (
                  <ThemedButtonRocket
                    variant="secondary"
                    id="delete-button"
                    data-testid="delete-button"
                    label="Delete"
                    onClick={onDeleteButtonClick}
                  />
                )}
              </RocketButtonRightContainer>
            </RocketButtonsContainer>
          </AddAddressFormContainerRocket>
        </AddAddressContainerRocket>
      )}
    </>
  );
}
