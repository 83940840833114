import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updatePasswordAction } from "../../store/update-password/UpdatePasswordActions";
import {
  currentPasswordChanged,
  newPasswordChanged,
} from "../../store/update-password/UpdatePasswordSlice";
import { RootState } from "../../store/Store";
import { PasswordStrengthChecker } from "../../common/password-strength-checker/PasswordStrengthChecker";
import {
  setUserUpdatesPasswordFlag,
  updateIsLoadingUpdatePassword,
} from "../../store/customer-profile/CustomerProfileSlice";
import ThemedPasswordInput from "./ThemedPasswordInput";
import {
  newPasswordMatches,
  validateNewPassword,
  MaxLength,
} from "./validation/validation";
import { Layout } from "../styled/Layout";
import { InputDiv } from "../styled/InputDiv";
import { Password } from "../../api/ServerApi";
import {
  HideableDiv,
  ThemedButton,
  ThemedButtonRocket,
  PasswordInputFieldRocket,
} from "ccp-common-ui-components";
import { PageLoader } from "../common/PageLoader";
import {
  ChangePasswordContainer,
  RequiredFieldsText,
  ThemedInputLabel,
  ThemedErrorText,
  CancelLink,
  ButtonsContainer,
  RequiredFieldsTextRocket,
  ChangePasswordContainerRocket,
  InputDivStyled,
} from "./ChangePasswordFormStyles";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import { PasswordStrengthCheckerRocket } from "../../common/password-strength-checker/PasswordStrengthCheckerRocket";

interface ChangePasswordFormProps {
  onCancel(): void;
}

export function ChangePasswordForm({ onCancel }: ChangePasswordFormProps) {
  const dispatch = useDispatch();
  const isRocketEnabled = useRocketEnabled();
  const ChangePasswordContainerDiv = isRocketEnabled
    ? ChangePasswordContainerRocket
    : ChangePasswordContainer;
  const InputDivContainer = isRocketEnabled ? InputDivStyled : InputDiv;
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    watch,
  } = useForm<Password>();

  const [userTriedToSubmit, setUserTriedToSubmit] = React.useState(false);

  function revalidate(
    name: "currentPassword" | "newPassword" | "confirmNewPassword"
  ) {
    if (userTriedToSubmit) {
      trigger(name);
    }
  }

  const onSubmit = (data: Password) => {
    setUserTriedToSubmit(true);
    dispatch(updateIsLoadingUpdatePassword(true));
    dispatch(setUserUpdatesPasswordFlag(true));
    dispatch(
      updatePasswordAction({
        data,
      })
    );
  };
  const customerProfile = useSelector(
    (state: RootState) => state.customerProfile
  );
  const passwords = useSelector((state: RootState) => state.updatePassword);
  const isLoadingUpdatePassword = customerProfile.isLoadingUpdatePassword;
  const currentPasswordFormError =
    errors.currentPassword &&
    (errors.currentPassword.message
      ? errors.currentPassword.message
      : "Current password is required");

  const currentPasswordFormErrors =
    currentPasswordFormError || passwords.currentPasswordError;

  const newPasswordFormError =
    errors.newPassword &&
    (errors.newPassword.message
      ? errors.newPassword.message
      : "New password is required");

  const newPasswordFormErrors =
    newPasswordFormError || passwords.newPasswordError;

  const confirmNewPasswordFormError =
    errors.confirmNewPassword &&
    (errors.confirmNewPassword.message
      ? errors.confirmNewPassword.message
      : "Confirm new password is required");

  const newPasswordValue = watch("newPassword");
  const confirmNewPasswordValue = watch("confirmNewPassword");

  const newPasswordsMatch = () => {
    if (!newPasswordValue || !confirmNewPasswordValue) {
      return false;
    }
    return newPasswordValue === confirmNewPasswordValue;
  };

  const currentPasswordHasErrors = () => {
    return !!errors.currentPassword || !!passwords.currentPasswordError;
  };

  const newPasswordHasErrors = () => {
    return !!errors.newPassword || !!passwords.newPasswordError;
  };

  const confirmNewPasswordHasErrors = () => {
    return !!errors.confirmNewPassword;
  };

  const newPasswordDisplaySuccessIcon = () => {
    return newPasswordsMatch() && !newPasswordHasErrors();
  };

  const confirmNewPasswordDisplaySuccessIcon = () => {
    return (
      newPasswordsMatch() &&
      !newPasswordHasErrors() &&
      !confirmNewPasswordHasErrors()
    );
  };

  const PasswordChecker = isRocketEnabled ? (
    <div>
      <PasswordStrengthCheckerRocket
        password={newPasswordValue}
        firstName={customerProfile.profileFields.firstName}
        lastName={customerProfile.profileFields.lastName}
        mobile={customerProfile.profileFields.mobile}
        email={customerProfile.profileFields.email}
      />
    </div>
  ) : (
    <div>
      <span>
        Your new password must contain at least 8 characters and one number
      </span>

      <PasswordStrengthChecker
        password={newPasswordValue}
        firstName={customerProfile.profileFields.firstName}
        lastName={customerProfile.profileFields.lastName}
        mobile={customerProfile.profileFields.mobile}
        email={customerProfile.profileFields.email}
      />
    </div>
  );

  const currentPasswordInput = isRocketEnabled ? (
    <PasswordInputFieldRocket
      autoFocus
      id="CurrentPassword"
      aria-invalid={!!errors.currentPassword}
      aria-describedby="CurrentPassword-error"
      data-testid="CurrentPassword-input"
      maxLength={MaxLength.Password}
      hasError={currentPasswordHasErrors()}
      labelText="Current password"
      errorText={currentPasswordFormErrors ?? ""}
      {...register("currentPassword", {
        required: true,
        onChange: (e) => {
          revalidate("currentPassword");
          dispatch(currentPasswordChanged());
        },
      })}
    />
  ) : (
    <ThemedPasswordInput
      id="CurrentPassword"
      aria-invalid={!!errors.currentPassword}
      aria-describedby="CurrentPassword-error"
      autoFocus
      data-testid="CurrentPassword-input"
      {...register("currentPassword", {
        required: true,
        onChange: (e) => {
          revalidate("currentPassword");
          dispatch(currentPasswordChanged());
        },
      })}
      hasError={currentPasswordHasErrors()}
      displaySuccessIcon={false}
    />
  );

  const newPasswordInput = isRocketEnabled ? (
    <PasswordInputFieldRocket
      id="NewPassword"
      aria-invalid={!!errors.newPassword}
      aria-describedby="NewPassword-error"
      data-testid="NewPassword-input"
      maxLength={MaxLength.Password}
      hasError={newPasswordHasErrors()}
      labelText="New password"
      descriptionText="At least 8 characters long and one number"
      errorText={newPasswordFormErrors ?? ""}
      {...register("newPassword", {
        required: true,
        validate: (newPassword) =>
          validateNewPassword(
            newPassword,
            customerProfile.profileFields.firstName,
            customerProfile.profileFields.lastName,
            customerProfile.profileFields.mobile,
            customerProfile.profileFields.email
          ),
        onChange: (e) => {
          revalidate("newPassword");
          dispatch(newPasswordChanged());
        },
      })}
    />
  ) : (
    <ThemedPasswordInput
      id="NewPassword"
      aria-invalid={!!errors.newPassword}
      aria-describedby="NewPassword-error"
      data-testid="NewPassword-input"
      {...register("newPassword", {
        required: true,
        validate: (newPassword) =>
          validateNewPassword(
            newPassword,
            customerProfile.profileFields.firstName,
            customerProfile.profileFields.lastName,
            customerProfile.profileFields.mobile,
            customerProfile.profileFields.email
          ),
        onChange: (e) => {
          revalidate("newPassword");
          dispatch(newPasswordChanged());
        },
      })}
      hasError={newPasswordHasErrors()}
      displaySuccessIcon={newPasswordDisplaySuccessIcon()}
    />
  );

  const confirmNewPasswordInput = isRocketEnabled ? (
    <PasswordInputFieldRocket
      id="ConfirmNewPassword"
      aria-invalid={!!errors.confirmNewPassword}
      aria-describedby="ConfirmNewPassword-error"
      data-testid="ConfirmNewPassword-input"
      maxLength={MaxLength.Password}
      hasError={confirmNewPasswordHasErrors()}
      labelText="Confirm new password"
      errorText={confirmNewPasswordFormError}
      {...register("confirmNewPassword", {
        required: true,
        validate: (confirmNewPassword: string) =>
          newPasswordMatches(newPasswordValue, confirmNewPassword),
        onChange: () => revalidate("confirmNewPassword"),
      })}
    />
  ) : (
    <ThemedPasswordInput
      id="ConfirmNewPassword"
      aria-invalid={confirmNewPasswordHasErrors()}
      aria-describedby="ConfirmNewPassword-error"
      data-testid="ConfirmNewPassword-input"
      {...register("confirmNewPassword", {
        required: true,
        validate: (confirmNewPassword: string) =>
          newPasswordMatches(newPasswordValue, confirmNewPassword),
        onChange: () => revalidate("confirmNewPassword"),
      })}
      hasError={confirmNewPasswordHasErrors()}
      displaySuccessIcon={confirmNewPasswordDisplaySuccessIcon()}
    />
  );

  return (
    <Layout>
      <ChangePasswordContainerDiv>
        <PageLoader isShown={isLoadingUpdatePassword} />
        <HideableDiv isHidden={isLoadingUpdatePassword} data-testid="hideable">
          {isRocketEnabled ? (
            <RequiredFieldsTextRocket role="status">
              All fields are required unless marked as optional.
            </RequiredFieldsTextRocket>
          ) : (
            <RequiredFieldsText role="status">
              All fields are required unless marked as optional
            </RequiredFieldsText>
          )}
          <form
            id="change-password-form"
            data-testid="change-password-form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div>
              <InputDivContainer>
                {isRocketEnabled ? null : (
                  <ThemedInputLabel
                    htmlFor="CurrentPassword"
                    hasError={currentPasswordHasErrors()}
                  >
                    Current password:
                  </ThemedInputLabel>
                )}
                {currentPasswordInput}
                {isRocketEnabled
                  ? null
                  : (errors.currentPassword ||
                      passwords.currentPasswordError) && (
                      <ThemedErrorText id="CurrentPassword-error">
                        {currentPasswordFormError}
                        {passwords.currentPasswordError}
                      </ThemedErrorText>
                    )}
              </InputDivContainer>

              <InputDivContainer>
                {isRocketEnabled ? null : (
                  <ThemedInputLabel
                    htmlFor="NewPassword"
                    hasError={newPasswordHasErrors()}
                  >
                    New password:
                  </ThemedInputLabel>
                )}
                {newPasswordInput}

                {isRocketEnabled
                  ? null
                  : (errors.newPassword || passwords.newPasswordError) && (
                      <ThemedErrorText id="NewPassword-error">
                        {newPasswordFormError}
                        {passwords.newPasswordError}
                      </ThemedErrorText>
                    )}
              </InputDivContainer>

              {isRocketEnabled ? PasswordChecker : null}
              <InputDivContainer>
                {isRocketEnabled ? null : (
                  <ThemedInputLabel
                    htmlFor="ConfirmNewPassword"
                    hasError={confirmNewPasswordHasErrors()}
                  >
                    Confirm new password:
                  </ThemedInputLabel>
                )}
                {confirmNewPasswordInput}
                {isRocketEnabled
                  ? null
                  : errors.confirmNewPassword && (
                      <ThemedErrorText id="ConfirmNewPassword-error">
                        {confirmNewPasswordFormError}
                      </ThemedErrorText>
                    )}
              </InputDivContainer>
            </div>
            {isRocketEnabled ? null : PasswordChecker}
            <ButtonsContainer>
              {isRocketEnabled ? (
                <ThemedButtonRocket
                  isFullWidth
                  label="Save"
                  data-testid="save-button-rocket"
                />
              ) : (
                <ThemedButton>Save password</ThemedButton>
              )}
              {isRocketEnabled ? null : (
                <CancelLink tabIndex={0} onClick={onCancel}>
                  Cancel
                </CancelLink>
              )}
            </ButtonsContainer>
          </form>
        </HideableDiv>
      </ChangePasswordContainerDiv>
    </Layout>
  );
}
