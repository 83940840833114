import styled from "styled-components";
import { Ref } from "react";
import {
  Col,
  rdsMargin,
  rdsPadding,
  Row,
  Spacing,
  StyledLabel,
} from "@coles/rocket";
import {
  BodyTextRocket,
  InputFieldRocket,
  InputFieldRocketProps,
  LinkRocket,
  ThemedButtonRocket,
} from "ccp-common-ui-components";
import { screenSize } from "../../common/styles/ScreenSizes";
import { useHistory } from "react-router-dom";

export interface SettingProps<TValue = "string", TDisplayValue = TValue> {
  readonly name: string;
  readonly value?:
    | TValue
    | TDisplayValue
    | string
    | JSX.Element
    | JSX.Element[];
  readonly link?: string | React.ReactNode;
  readonly handleEdit?: (event: SettingClickEvent) => void;
  readonly editLink?: string;
  readonly handleSave?: (event: SettingClickEvent) => void;
  readonly handleChange?: (event: SettingChangeEvent) => void;
  readonly handleCancel?: () => void;
  readonly status?: SettingFieldStatus;
  readonly id: string;
  readonly linkEnabled: boolean;
  readonly MessageComponent?: React.FunctionComponent;
  readonly editButtonRef?: React.Ref<HTMLButtonElement>;
  readonly inputRef?: Ref<HTMLInputElement>;
  readonly inputType?: InputFieldRocketProps["type"];
  readonly NameComponent?: React.FunctionComponent;
  readonly InputComponent?: React.FunctionComponent<
    CustomInputComponentProps<TValue>
  >;
  readonly DisplayComponent?: React.FunctionComponent<{
    value?: TDisplayValue;
  }>;
  readonly isLoading?: boolean;
}

export type SettingClickEvent = React.MouseEvent;
export type SettingChangeEvent = React.ChangeEvent<HTMLInputElement>;
export enum SettingFieldStatus {
  Readable,
  Editable,
  PendingUpdate,
}

export type CustomInputComponentProps<TValue> = {
  autoFocus: true;
  id: string;
  onChange: (e: SettingChangeEvent) => void;
  value?: SettingProps<TValue>["value"];
  "aria-invalid": boolean;
  "aria-describedby": string;
  hasError: boolean;
  required: boolean;
  type: string;
};

const MiddleAlignedStyledLabel = styled(StyledLabel)`
  justify-content: start;
  display: flex;
  align-items: center;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: ${Spacing.s1}px;
  ${rdsMargin.s2.top};
`;

const AlignEndCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const SettingLineRow = styled(Row)`
  justify-content: flex-start;
  align-items: baseline;

  @media ${screenSize.mobile} {
    flex-flow: row wrap;
    height: auto;
  }
`;

const TopGap = styled.div`
  ${rdsMargin.s2.top};
`;

const LoadingButton = styled(ThemedButtonRocket)`
  ${rdsPadding.s3.horizontal}
`;

function ProfileSettingRocket<TValue, TDisplayValue = TValue>({
  status,
  id,
  inputRef,
  inputType,
  handleChange,
  handleEdit,
  editLink,
  handleSave,
  handleCancel,
  linkEnabled,
  value,
  link,
  MessageComponent,
  NameComponent,
  DisplayComponent,
  InputComponent,
  name,
  isLoading,
}: SettingProps<TValue, TDisplayValue>) {
  const { push } = useHistory();
  const settingProps: {
    htmlFor?: string;
    id?: string;
    error?: string | null;
  } = {};
  if (status === SettingFieldStatus.Editable) {
    settingProps.htmlFor = settingProps.id = id;
  }
  const editHandler = editLink
    ? () => {
        push(editLink);
      }
    : handleEdit;
  const InputComponentOrDefault = InputComponent || InputFieldRocket;
  const settingValue =
    status === SettingFieldStatus.Editable && settingProps.id ? (
      <InputComponentOrDefault
        autoFocus
        ref={inputRef}
        id={settingProps.id}
        onChange={handleChange!}
        value={value as string}
        aria-invalid={!linkEnabled}
        aria-describedby={`${id}-error`}
        hasError={!linkEnabled}
        required
        type={inputType || "text"}
      />
    ) : (
      <BodyTextRocket
        variant="body_300"
        id={`${id}-value`}
        data-testid={`${id}-display`}
      >
        {DisplayComponent ? (
          <DisplayComponent value={value as TDisplayValue} />
        ) : (
          (value as JSX.Element) || "-"
        )}
      </BodyTextRocket>
    );

  const settingLink =
    status === SettingFieldStatus.PendingUpdate ? (
      <LoadingButton
        id={`${id}-pending`}
        disabled
        aria-label="Updating"
        variant="textPrimary"
        data-testid={`pending-${id}`}
        isLoading
        size="medium"
      />
    ) : (
      <LinkRocket
        id={`${id}-button`}
        onClick={editHandler}
        disabled={!linkEnabled}
        label={link as string}
        variant="primary"
        data-testid={`edit-${id}`}
        role="button"
      />
    );

  return (
    <SettingLineRow>
      <Col initialSpan={8}>
        <MiddleAlignedStyledLabel htmlFor={settingProps.htmlFor}>
          {NameComponent ? <NameComponent /> : name}
        </MiddleAlignedStyledLabel>
        {settingValue}
        {(status === SettingFieldStatus.Editable || isLoading) &&
          handleSave && (
            <ButtonRow>
              <ThemedButtonRocket
                size="small"
                onClick={handleSave}
                isLoading={isLoading}
                data-testid={`${id}-save`}
              >
                Save
              </ThemedButtonRocket>
              {handleCancel && (
                <ThemedButtonRocket
                  size="small"
                  variant="textSecondary"
                  noLinkHover
                  onClick={() => handleCancel()}
                >
                  Cancel
                </ThemedButtonRocket>
              )}
            </ButtonRow>
          )}
      </Col>
      <AlignEndCol initialSpan={4}>
        {status !== SettingFieldStatus.Editable && settingLink}
      </AlignEndCol>
      <Col initialSpan={12}>
        {MessageComponent !== undefined && (
          <TopGap data-testid={`${id}-message`}>
            <MessageComponent />
          </TopGap>
        )}
      </Col>
    </SettingLineRow>
  );
}

export interface ReadonlyProfileSettingRocketProps {
  readonly name: string;
  readonly id: string;
  readonly value: string | JSX.Element[];
  readonly NameComponent?: React.FunctionComponent;
}

export const ReadonlyProfileSettingRocket = ({
  NameComponent,
  name,
  id,
  value,
}: ReadonlyProfileSettingRocketProps) => {
  return (
    <SettingLineRow>
      <Col initialSpan={8}>
        <StyledLabel htmlFor={id}>
          {NameComponent ? <NameComponent /> : name}
        </StyledLabel>
        <BodyTextRocket
          variant="body_300"
          id={id}
          data-testid={`${id}-display-readonly`}
        >
          {value}
        </BodyTextRocket>
      </Col>
    </SettingLineRow>
  );
};

export default ProfileSettingRocket;
