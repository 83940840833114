import React, { useEffect, useRef, useState } from "react";
import { SettingButton } from "../../styled/SettingButton";
import { RootState } from "../../../store/Store";
import { useDispatch, useSelector } from "react-redux";
import { DateOfBirthDisplay } from "./DateOfBirthDisplay";
import { EditableDateOfBirth } from "./EditableDateOfBirth";
import { useForm } from "react-hook-form";
import {
  cancelDateOfBirthUpdate,
  updateDateOfBirth,
} from "../../../store/customer-profile/CustomerProfileSlice";
import { UpdatePendingSpinner } from "../UpdatePendingSpinner";
import { validateDateOfBirth } from "./validateDateOfBirth";
import { dateOfBirthPlaceholder } from "../../../common/constants/Placeholders";
import { SettingLine } from "../../styled/SettingLine";
import { SettingName } from "../../styled/SettingName";
import { SpinnerContainer } from "../../styled/SpinnerContainer";
import ProfileSettingRocket, {
  SettingClickEvent,
  SettingFieldStatus,
} from "../ProfileSettingRocket";
import useRocketEnabled from "../../../hooks/useRocketEnabled";
import { ThemedMessageRocket } from "ccp-common-ui-components";

type FormData = {
  dateOfBirth: string;
};

const getValidationFailedMessage = (error: string) => () => {
  return (
    <ThemedMessageRocket
      type="error"
      data-testid="error-text"
      text={`${error} Please try again.`}
    />
  );
};
const UpdateSuccessfulMessage = () => {
  return (
    <ThemedMessageRocket
      type="success"
      text="Your date of birth has been updated."
    />
  );
};
const UpdateFailedMessage = () => {
  return (
    <ThemedMessageRocket
      type="error"
      text="We weren’t able to update your date of birth. Please try again later."
    />
  );
};

function isDateOfBirthEmpty(value: string) {
  return !value || value === dateOfBirthPlaceholder;
}

export function DateOfBirthSetting() {
  const dateOfBirthInputName = "dateOfBirth";
  const [isEditing, setIsEditing] = useState(false);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const editButtonRef = useRef<HTMLButtonElement>(null);
  const { isUpdatingDateOfBirth } = useSelector(
    (state: RootState) => state.customerProfile
  );
  const { dateOfBirth: originalDateOfBirth } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );
  const dateOfBirthRef = useRef(originalDateOfBirth);
  const hasPreExistingDateOfBirth = !!originalDateOfBirth;
  const isRocketEnabled = useRocketEnabled();
  const [Message, setMessage] = useState<React.FunctionComponent>();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    getValues,
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm<FormData>();

  const dispatch = useDispatch();

  const onEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    clearErrors();
    setIsEditing(true);
    setIsEditClicked(true);
  };

  const onSubmit = (data: FormData) => {
    const newDateOfBirth = data.dateOfBirth;
    const dateOfBirthNotUpdated = newDateOfBirth === originalDateOfBirth;
    if (
      dateOfBirthNotUpdated ||
      (!hasPreExistingDateOfBirth && isDateOfBirthEmpty(newDateOfBirth))
    ) {
      dispatch(cancelDateOfBirthUpdate());
      setMessage(() => UpdateFailedMessage);
    } else {
      dispatch(updateDateOfBirth(data.dateOfBirth));
    }
    reset();
    setIsEditing(false);
    setMessage(() => UpdateSuccessfulMessage);
  };

  const buttonText = () => {
    if (isEditing) {
      return "Save date of birth";
    } else if (originalDateOfBirth) {
      return "Edit date of birth";
    } else {
      return "Add date of birth";
    }
  };

  // Focus edit button after going back to read mode
  useEffect(() => {
    if (
      isEditClicked &&
      !isEditing &&
      editButtonRef.current &&
      !isUpdatingDateOfBirth
    ) {
      editButtonRef.current.focus();
    }
  }, [isEditClicked, isEditing, isUpdatingDateOfBirth]);

  // Focus input in edit mode
  useEffect(() => {
    if (isEditing) {
      document.getElementById(dateOfBirthInputName)?.focus();
    }
  }, [isEditing]);

  // Set default date of birth value
  useEffect(() => {
    dateOfBirthRef.current = originalDateOfBirth;
    setValue(dateOfBirthInputName, originalDateOfBirth);
  }, [originalDateOfBirth, setValue, isSubmitSuccessful]);

  // Reset the form after successful submission and re-editing the form
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const setDateOfBirth = (value: any) => {
    const isDateOfBirthInputEmpty = isDateOfBirthEmpty(value);
    const shouldValidate =
      isSubmitted && (hasPreExistingDateOfBirth || !isDateOfBirthInputEmpty);

    setValue(dateOfBirthInputName, value, {
      shouldDirty: true,
      shouldValidate,
    });
    dateOfBirthRef.current = value;

    if (!shouldValidate) {
      clearErrors();
    }
  };

  const editMode = (
    <>
      <EditableDateOfBirth
        autoFocus
        id="date-of-birth"
        onAccept={setDateOfBirth}
        value={dateOfBirthRef.current}
        errorMessage={errors.dateOfBirth?.message}
        required={hasPreExistingDateOfBirth}
        {...register(dateOfBirthInputName, {
          validate: {
            validDate: (value) =>
              validateDateOfBirth(value, originalDateOfBirth),
          },
        })}
      />

      <SpinnerContainer>
        <SettingButton type="submit" data-testid="save-button">
          {buttonText()}
        </SettingButton>
      </SpinnerContainer>
    </>
  );

  const readMode = (
    <>
      <DateOfBirthDisplay />
      <SpinnerContainer>
        {isUpdatingDateOfBirth ? (
          <UpdatePendingSpinner />
        ) : (
          <SettingButton
            data-testid="edit-button"
            ref={editButtonRef}
            type="button"
            withIcon={hasPreExistingDateOfBirth}
            onClick={(e) => onEditButtonClick(e)}
          >
            {buttonText()}
          </SettingButton>
        )}
      </SpinnerContainer>
    </>
  );

  const enterEditState = () => {
    setMessage(undefined);
    setIsEditing(true);
    setIsEditClicked(true);
  };

  const exitEditState = () => {
    setIsEditing(false);
    setIsEditClicked(false);
    reset();
    setMessage(undefined);
  };

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateOfBirth(event.target.value);
  };

  const handleSave = (_event: SettingClickEvent) => {
    const values = getValues();
    const validOrError = values.dateOfBirth
      ? validateDateOfBirth(values.dateOfBirth, originalDateOfBirth)
      : false;
    if (validOrError === true) {
      onSubmit(values);
    } else {
      setMessage(() =>
        getValidationFailedMessage(
          validOrError || "This date of birth is invalid."
        )
      );
      document.getElementById(dateOfBirthInputName)?.focus();
    }
  };

  return isRocketEnabled ? (
    <ProfileSettingRocket
      name="Date of birth"
      value={getValues().dateOfBirth || originalDateOfBirth}
      handleEdit={enterEditState}
      handleCancel={exitEditState}
      handleChange={updateValue}
      handleSave={handleSave}
      id={dateOfBirthInputName}
      inputType="date"
      status={
        isEditing
          ? SettingFieldStatus.Editable
          : isUpdatingDateOfBirth
          ? SettingFieldStatus.PendingUpdate
          : SettingFieldStatus.Readable
      }
      MessageComponent={Message}
      linkEnabled
      link={originalDateOfBirth ? "Edit" : "Add"}
    />
  ) : (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="date-of-birth-form"
      noValidate
    >
      <SettingLine>
        <SettingName htmlFor="date-of-birth">Date of birth:</SettingName>
        {isEditing ? editMode : readMode}
      </SettingLine>
    </form>
  );
}
