import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import { rdsMargin, Row } from "@coles/rocket";

const SettingLineDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
  border-bottom: 1px solid #f0f0f0;
  padding: 18px;

  @media ${screenSize.mobile} {
    flex-flow: row wrap;
    height: auto;
    padding: 20px 18px;
  }
`;

const SettingLineRow = styled(Row)`
  ${rdsMargin.s3.vertical};
  justify-content: flex-start;
  align-items: baseline;

  @media ${screenSize.mobile} {
    flex-flow: row wrap;
    height: auto;
  }
`;

export const SettingLine = ({ children }: { children: React.ReactNode }) => {
  const isRocketEnabled = useRocketEnabled();
  return isRocketEnabled ? (
    <SettingLineRow>{children}</SettingLineRow>
  ) : (
    <SettingLineDiv>{children}</SettingLineDiv>
  );
};
